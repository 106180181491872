<template>
 <el-row :gutter="20" class="page-box task-list-page">
    <el-col>
      <Breadcrumb
        :home="false"
        :breadcrumbs="[
          {
            label: '日志',
            to: '/log',
          },
        ]"
      />
    </el-col>
    <el-col>
      <el-card class="grid-card" shadow="never">
  <SchemaTable
      ref="schematableref"
      :schema=schema
      :model=dataModel
      :filters=filters
      :queryConf=queryConf
      :hasReset=hasReset
      :canCreate=canCreate
      :hasAction="false"

  >

  </SchemaTable>
  </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {SchemaTable, DataModel} from 'enn-schema-table'
import schema from './opLog.schema.json'
import { uris, attachId } from "./../../api/uri";
import Breadcrumb from "enn-breadcrumb";

export default {
  components: {
    SchemaTable,
    Breadcrumb,
  },
  data() {
    return {
      search: "",
      filters: [ 'slaveId' ],
      schema,
      hasReset: true,
      canCreate: false,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.opLog}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          item.timeStart = +new Date(item.timeStart);
          item.timeEnd = +new Date(item.timeEnd);
        
          return item;
        },
        getApi: `${uris.opLog}${attachId}`,
        getMap(res) {
          return res;
        },
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {}
}
</script>

<style scoped>

</style>